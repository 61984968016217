import { asyncComponent } from '@jaredpalmer/after';

export default [
  // codesplit route
  {
    path: '/',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/Home/Home'),
    }),
  },
  {
    path: '/about-us',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/About/About'),
    }),
  },
  {
    path: '/myhome-account-login',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/PortalLanding/PortalLanding'),
    }),
  },
  {
    path: '/homes/:serialNumber/brochure',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/Brochure/Brochure'),
    }),
  },
  {
    path: '/homes/model/:modelNumber/brochure',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/Brochure/Brochure'),
    }),
  },
  {
    path: '/homes',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/HomeList/HomeList'),
    }),
  },
  {
    path: '/homes/new',
    component: asyncComponent({
      loader: () => import('./components/NotFound/NotFound'),
    }),
  },
  {
    path: '/homes/used',
    component: asyncComponent({
      loader: () => import('./components/NotFound/NotFound'),
    }),
  },
  {
    path: '/homes/:stocknumber/images',
    component: asyncComponent({
      loader: () => import('./components/NotFound/NotFound'),
    }),
  },
  {
    path: '/land',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/HomeList/HomeList'),
    }),
  },
  {
    path: '/construction',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/Construction/Construction'),
    }),
  },
  {
    path: '/contact-us',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/Contact/Contact'),
    }),
  },
  {
    path: '/crossmod',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/CrossMod/CrossMod'),
    }),
  },
  {
    path: '/energysmart',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/EnergySmart/EnergySmart'),
    }),
  },
  {
    path: '/error',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/ErrorDisplay/ErrorDisplay'),
    }),
  },
  {
    path: '/faq',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/FAQ/FAQ'),
    }),
  },
  {
    path: '/facility-tour',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/FacilityTour/FacilityTour'),
    }),
  },
  {
    path: '/give-a-testimonial',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/Testimonials/Testimonials'),
    }),
  },
  {
    path: '/site-feedback',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/SiteFeedback/SiteFeedback'),
    }),
  },
  {
    path: '/our-homeowners',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import('./components/WelcomeHomeGallery/WelcomeHomeGallery'),
    }),
  },
  {
    path: '/homes/:serialNumber',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/HomeDetails/HomeDetails'),
    }),
  },
  {
    path: '/homes/model/:modelNumber',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/HomeDetails/HomeDetails'),
    }),
  },
  {
    path: '/interactive-facility-tour',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import('./components/InteractiveFacilityTour/InteractiveFacilityTour'),
    }),
  },
  {
    path: '/land/:stockNumber',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/HomeDetails/HomeDetails'),
    }),
  },
  {
    path: '/legal',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/LegalPrivacy/LegalPrivacy'),
    }),
  },
  {
    path: '/housesmart',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/HouseSmart/HouseSmart'),
    }),
  },
  {
    path: '/learn',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/Learn/Learn'),
    }),
  },
  {
    path: '/learn/:slug',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/LearnPost/LearnPost'),
    }),
  },
  {
    path: '/news-events',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/NewsEvents/NewsEvents'),
    }),
  },
  {
    path: '/special-offers/trade-in',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/Promo/Promo'),
    }),
  },
  {
    path: '/special-offers/trade-in/thank-you',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/Promo/PromoThankYou'),
    }),
  },
  {
    path: '/news/:slug',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/NewsEventsPost/NewsEventsPost'),
    }),
  },
  {
    path: '/event/:slug',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/NewsEventsPost/NewsEventsPost'),
    }),
  },
  {
    path: '/lp/:lotNumber/affordable-homes',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import('./components/LandingPages/AffordableHomes/AffordableHomes'),
    }),
  },
  {
    path: '/sale-homes',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import('./components/CuratedCollections/CuratedCollections'),
    }),
  },
  {
    path: '/mobile-manufactured-homes-on-display',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import('./components/CuratedCollections/CuratedCollections'),
    }),
  },
  {
    path: '/mobile-manufactured-homes-with-land',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import('./components/CuratedCollections/CuratedCollections'),
    }),
  },
  {
    path: '/mobile-manufactured-homes-under-150k',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import('./components/CuratedCollections/CuratedCollections'),
    }),
  },
  {
    path: '/special-offers',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/SpecialOffers/SpecialOffers'),
    }),
  },
  {
    path: '/unbuilt',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/Unbuilt/Unbuilt'),
    }),
  },
  {
    path: '/special-offers/red-tag-sales-event',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/FallPromo/FallPromo'),
    }),
  },
  {
    path: '/special-offers/red-tag-sales-event/thank-you/',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/FallPromo/FallPromoThankYou'),
    }),
  },
  {
    path: '/special-offers/go-big-go-home',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/WinterPromo/WinterPromo'),
    }),
  },
  {
    path: '/special-offers/go-big-go-home/thank-you',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/WinterPromo/WinterPromoThankYou'),
    }),
  },
  {
    path: '/special-offers/ebuilt-sales-event',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/SummerPromo/SummerPromo'),
    }),
  },
  {
    path: '/special-offers/ebuilt-sales-event/thank-you',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/SummerPromo/SummerPromoThankYou'),
    }),
  },
  {
    path: '/review',
    exact: true,
    component: asyncComponent({
      loader: () => import('./components/Review/Review'),
    }),
  },

  //PORTAL
  {
    path: '/portal',
    exact: true,
    component: asyncComponent({
      loader: () => import('./portal/pages/Dashboard/Dashboard'),
    }),
  },
  {
    path: '/portal/planner',
    exact: true,
    component: asyncComponent({
      loader: () => import('./portal/pages/MyHomePlanner/MyHomePlanner'),
    }),
  },
  {
    path: '/portal/home-buying-journey',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import('./portal/pages/HomeBuyingJourney/HomeBuyingJourney'),
    }),
  },
  {
    path: '/portal/homes-for-you',
    exact: true,
    component: asyncComponent({
      loader: () => import('./portal/pages/HomesForYou/HomesForYou'),
    }),
  },
  {
    path: '/portal/my-home-aspirations',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import('./portal/pages/MyHomeAspirations/MyHomeAspirations'),
    }),
  },
  {
    path: '/portal/favorites',
    exact: true,
    component: asyncComponent({
      loader: () => import('./portal/pages/Favorites/Favorites'),
    }),
  },
  {
    path: '/portal/budget',
    exact: true,
    component: asyncComponent({
      loader: () => import('./portal/pages/Budget/Budget'),
    }),
  },
  {
    path: '/portal/land',
    exact: true,
    component: asyncComponent({
      loader: () => import('./portal/pages/Land/Land'),
    }),
  },
  {
    path: '/portal/account-details',
    exact: true,
    component: asyncComponent({
      loader: () => import('./portal/pages/AccountDetails/AccountDetails'),
    }),
  },
  {
    //404
    component: asyncComponent({
      loader: () => import('./components/NotFound/NotFound'),
    }),
  },
];
